



































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Stack, StackItem } from "vue-stack-grid";

interface dataGaleria {
  id: number | any;
  nombre: string;
  nombre_web: string;
  descripcion: string;
  imagenes: Array<any>;
}

@Component({
  components: {
    Stack,
    StackItem
  }
})
export default class DetalleGaleria extends Vue {
  private nombreWeb: any;
  private slide = 0;
  private galeria: dataGaleria = {
    id: 0,
    nombre: "",
    nombre_web: "",
    descripcion: "",
    imagenes: []
  };
  private selectedImage = "";
  private dialogVerImagen = false;

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getImagenesGaleria();
      this.linkCanonical(this.nombreWeb);
    }
  }

  private linkCanonical(nombreWeb) {
    var canonicalLink = document.createElement("link");
    // Establece los atributos del enlace

    canonicalLink.rel = "canonical";
    canonicalLink.href = "https://muniarica.cl/galeria/" + nombreWeb;
    document.head.appendChild(canonicalLink);
  }

  private getImagenesGaleria() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("galeria/detalle?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        this.galeria = res.data;
        let base = process.env.VUE_APP_BASE_URL;
        for (let i = 0; i < this.galeria.imagenes.length; i++) {
          if (
            this.galeria.imagenes[i].imagen &&
            this.galeria.imagenes[i].imagen != ""
          ) {
            this.galeria.imagenes[i].imagen =
              base + this.galeria.imagenes[i].imagen;
            this.galeria.imagenes[i].imagen;
          } else {
            this.galeria.imagenes[
              i
            ].imagen = require("@/assets/logos/logo300.webp");
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private verImagen(index: any) {
    if ((index && index != "") || index == 0) {
      this.slide = index + 1;
      this.dialogVerImagen = true;
    }
  }
}
